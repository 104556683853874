import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequiredItemsChecklistService {
  private subawardForm: UntypedFormGroup;
  private checklist: UntypedFormGroup;
  private noaCompleted = false;
  public checklistCompleted = new BehaviorSubject<boolean>(false);

  constructor() {}

  subscribeChecklist(subForm, checklistForm) {
    this.subawardForm = subForm;
    this.checklist = checklistForm;

    this.subawardForm.controls.contact.valueChanges.subscribe((contacts) => {
      this.checkContactInfo(contacts);
      this.setChecklistStatus();
    });

    // disable per story 778
    // this.subawardForm.controls.subrecipientInformation
    //   .get('institutionInformationFileId')
    //   .valueChanges.subscribe(id => {
    //     this.checkCollaboratorInstitutionalInfo(id);
    //     this.setChecklistStatus();
    //   });

    // set as checked while it is disabled pe story 778
    this.checklist.controls.collaboratorInstitutionalInfo.setValue(1);

    this.subawardForm.controls.subrecipientInformation
      .get('dataMgmtSharingPlan')
      .valueChanges.subscribe((dmsp) => {
        this.checkDataMgmtSharingPlan(dmsp);
        this.setChecklistStatus();
      });

    this.subawardForm.controls.subrecipientInformation
      .get('placeOfPerformance')
      .valueChanges.subscribe((place) => {
        this.checkPlaceOfPerformance(place);
        this.setChecklistStatus();
      });

    this.subawardForm.controls.subrecipientInformation
      .get('collaboratorScopeOfWorkFileId')
      .valueChanges.subscribe((id) => {
        this.checkScopeOfWork(id);
        this.setChecklistStatus();
      });

    this.subawardForm.controls.subrecipientInformation
      .get('riskCategory')
      .valueChanges.subscribe((cat) => {
        this.checkRiskCategory(cat);
        this.setChecklistStatus();
      });

    this.subawardForm.controls.subrecipientInformation
      .get('animalSubjects')
      .valueChanges.subscribe((value) => {
        this.checkAnimalSubjects(value);
        this.setChecklistStatus();
      });

    this.subawardForm.controls.subrecipientInformation
      .get('humanSubjects')
      .valueChanges.subscribe((value) => {
        this.checkHumanSubjects(value);
        this.setChecklistStatus();
      });

    this.subawardForm.controls.subrecipientInformation
      .get('budget.fAndARateAgreementFileId')
      .valueChanges.subscribe((id) => {
        this.checkFAndARateAgreement(id);
        this.setChecklistStatus();
      });

    this.subawardForm.controls.washUInformation
      .get('grantWorkTag')
      .valueChanges.subscribe(() => {
        this.checkAccount();
        this.setChecklistStatus();
      });

    this.subawardForm.controls.washUInformation
      .get('costCenterId')
      .valueChanges.subscribe(() => {
        this.checkAccount();
        this.setChecklistStatus();
      });

    this.subawardForm.controls.subrecipientInformation
      .get('budget')
      .valueChanges.subscribe((budget) => {
        this.checkBudget(budget);
        this.setChecklistStatus();
      });
  }

  setNoticeOfAward(noa) {
    this.noaCompleted = noa.length > 0;
  }

  checkDataMgmtSharingPlan(dmsp) {
    if (dmsp === null) {
      this.checklist.controls.dataMgmtSharingPlan.setValue(false);
    } else {
      this.checklist.controls.dataMgmtSharingPlan.setValue(true);
    }
  }

  checkPlaceOfPerformance(placeOfPerformance) {
    const country: string = placeOfPerformance.address.countryCode;
    const stateProvince: string = placeOfPerformance.address.state;
    let placeOfPerformanceComplete: string;

    if (country === 'USA') {
      placeOfPerformanceComplete =
        placeOfPerformance.address.addressLine1 &&
        placeOfPerformance.address.zip &&
        placeOfPerformance.address.countryCode &&
        placeOfPerformance.address.state &&
        placeOfPerformance.address.city &&
        placeOfPerformance.institutionName;

      // Story 870 If the user selects Country = U.S.A. and State <> Puerto Rico, the Congressional District IS required entry.
      if (stateProvince !== 'PR') {
        placeOfPerformanceComplete =
          placeOfPerformanceComplete &&
          placeOfPerformance.address.congressionalDistrict;
      }
    } else {
      placeOfPerformanceComplete =
        placeOfPerformance.address.addressLine1 &&
        placeOfPerformance.address.countryCode &&
        placeOfPerformance.address.city &&
        placeOfPerformance.institutionName;
    }

    this.checklist.controls.placeOfPerformance.setValue(
      placeOfPerformanceComplete,
    );
  }

  checkAccount() {
    this.checklist.controls.account.setValue(
      this.subawardForm.controls.washUInformation.get('grantWorkTag').value &&
        this.subawardForm.controls.washUInformation.get('costCenterId').value,
    );
  }

  checkBudget(budget) {
    if (budget.indirectCostBaseMethod) {
      if (budget.indirectCostBaseMethod.toLowerCase() === 'othr') {
        this.checklist.controls.budget.setValue(
          budget.budgetFromDate &&
            budget.estimatedProjectPeriodFromDate &&
            budget.budgetThruDate &&
            budget.estimatedProjectPeriodToDate &&
            this.hasValue(budget.directCost) &&
            this.hasValue(budget.indirectCost) &&
            this.hasValue(budget.estimatedTotalFunding) &&
            this.hasValue(budget.fAndARate) &&
            this.hasValue(budget.indirectCostBaseMethod) &&
            this.hasValue(budget.indirectCostBaseMethodDesc) &&
            this.hasValue(budget.costSharingAmount),
        );
      } else {
        this.checklist.controls.budget.setValue(
          budget.budgetFromDate &&
            budget.estimatedProjectPeriodFromDate &&
            budget.budgetThruDate &&
            budget.estimatedProjectPeriodToDate &&
            this.hasValue(budget.directCost) &&
            this.hasValue(budget.indirectCost) &&
            this.hasValue(budget.estimatedTotalFunding) &&
            this.hasValue(budget.fAndARate) &&
            this.hasValue(budget.indirectCostBaseMethod) &&
            this.hasValue(budget.costSharingAmount),
        );
      }
    }
  }

  checkContactInfo(contacts) {
    const administrativeComplete =
      contacts.departmentalAdmin.firstName &&
      contacts.departmentalAdmin.lastName &&
      contacts.departmentalAdmin.emailAddress;

    let country: string;
    let principalInvestigatorComplete: string;
    let signatoryComplete: string;
    let institutionalAdminComplete: string;
    let institutionalFinancialComplete: string;

    principalInvestigatorComplete =
      contacts.principalInvestigator.firstName &&
      contacts.principalInvestigator.lastName &&
      contacts.principalInvestigator.emailAddress &&
      contacts.principalInvestigator.address.addressLine1 &&
      contacts.principalInvestigator.address.city &&
      contacts.principalInvestigator.address.countryCode;

    country = contacts.principalInvestigator.address.countryCode;
    if (country === 'USA') {
      principalInvestigatorComplete =
        principalInvestigatorComplete &&
        contacts.principalInvestigator.address.state &&
        contacts.principalInvestigator.address.zip;
    }

    signatoryComplete =
      contacts.signatory.firstName &&
      contacts.signatory.lastName &&
      contacts.signatory.emailAddress &&
      contacts.signatory.address.addressLine1 &&
      contacts.signatory.address.city &&
      contacts.signatory.address.countryCode;

    country = contacts.signatory.address.countryCode;
    if (country === 'USA') {
      signatoryComplete =
        signatoryComplete &&
        contacts.signatory.address.state &&
        contacts.signatory.address.zip;
    }

    institutionalAdminComplete =
      contacts.institutionalAdmin.firstName &&
      contacts.institutionalAdmin.lastName &&
      contacts.institutionalAdmin.emailAddress &&
      contacts.institutionalAdmin.address.addressLine1 &&
      contacts.institutionalAdmin.address.city &&
      contacts.institutionalAdmin.address.countryCode;

    country = contacts.institutionalAdmin.address.countryCode;
    if (country === 'USA') {
      institutionalAdminComplete =
        institutionalAdminComplete &&
        contacts.institutionalAdmin.address.state &&
        contacts.institutionalAdmin.address.zip;
    }

    institutionalFinancialComplete =
      contacts.institutionalFinancial.firstName &&
      contacts.institutionalFinancial.lastName &&
      contacts.institutionalFinancial.emailAddress &&
      contacts.institutionalFinancial.address.addressLine1 &&
      contacts.institutionalFinancial.address.city &&
      contacts.institutionalFinancial.address.countryCode;

    country = contacts.institutionalFinancial.address.countryCode;
    if (country === 'USA') {
      institutionalFinancialComplete =
        institutionalFinancialComplete &&
        contacts.institutionalFinancial.address.state &&
        contacts.institutionalFinancial.address.zip;
    }

    const washUPrincipalInvestigatorComplete =
      contacts.washUPrincipalInvestigator.firstName &&
      contacts.washUPrincipalInvestigator.lastName &&
      contacts.washUPrincipalInvestigator.emailAddress;

    const washUDepartmentAdminComplete =
      contacts.washUDepartmentAdmin.firstName &&
      contacts.washUDepartmentAdmin.lastName &&
      contacts.washUDepartmentAdmin.emailAddress;

    this.checklist.controls.contactInfo.setValue(
      administrativeComplete &&
        principalInvestigatorComplete &&
        signatoryComplete &&
        institutionalAdminComplete &&
        institutionalFinancialComplete &&
        washUPrincipalInvestigatorComplete &&
        washUDepartmentAdminComplete,
    );
  }

  // disable per story 778
  // checkCollaboratorInstitutionalInfo(id) {
  //   this.checklist.controls.collaboratorInstitutionalInfo.setValue(!!id);
  // }

  checkScopeOfWork(id) {
    this.checklist.controls.scopeOfWorkAttached.setValue(!!id);
  }

  checkFAndARateAgreement(id) {
    this.checklist.controls.fAndARateAgreementAttached.setValue(!!id);
  }

  checkRiskCategory(category) {
    this.checklist.controls.riskCategory.setValue(!!category);
  }

  checkHumanSubjects(value) {
    this.checklist.controls.humanSubjects.setValue(value !== null);
  }

  checkAnimalSubjects(value) {
    this.checklist.controls.animalSubjects.setValue(value !== null);
  }

  setChecklistStatus() {
    this.checklist.controls.noticeOfAward.setValue(this.noaCompleted);
    this.checklistCompleted.next(this.checkForFalsyValues());
  }

  private checkForFalsyValues() {
    const fields = [];
    for (const field in this.checklist.controls) {
      if (this.checklist.controls.hasOwnProperty(field)) {
        // disable collaboratorInstitutionalInfo checkbox requirement per story 778
        if (field !== 'collaboratorInstitutionalInfo') {
          fields.push(this.checklist.get(field).value);
        }
      }
    }
    return !fields.some((el) => el === null || el === '' || el === false);
  }

  private hasValue(value) {
    // need additional check due to 'falsy zero' in javascript
    // app allows $0.00 amounts, but javascript would return 'false' when 0 entered
    return value !== 'undefined' && value !== null && value !== '';
  }
}
