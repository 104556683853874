import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FeedbackService } from '../../form-layout/feedback.service';
import {
  CchRollupReportClient,
  CchRollupReportResponse,
} from '../../api.service';
import { ExcelExportMultipleDataSetsService } from '../excel-export-multiple-data-sets.service';

@Component({
  selector: 'subs-cch-rollup-report',
  templateUrl: './cch-rollup-report.component.html',
  providers: [FeedbackService],
})
export class CchRollupReportComponent implements OnInit {
  alertSubject = this.feedbackService.alerts;
  submitState = this.feedbackService.submitState;

  searchForm = this.fb.group({
    dateFrom: [''],
    dateThru: [''],
    cchStart: ['', Validators.pattern('^[0-9]*$')],
    cchEnd: ['', Validators.pattern('^[0-9]*$')],
    fisLegacyDeptNumber: ['', Validators.pattern('^[0-9]*$')],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private feedbackService: FeedbackService,
    private reportService: CchRollupReportClient,
    private excelExportMultipleDataSetsService: ExcelExportMultipleDataSetsService,
  ) {}

  ngOnInit(): void {
    this.searchForm.setValidators([
      this.hasOneFieldFilled,
      this.hasbothDateFields,
      this.hasbothCCHRoleupFields,
    ]);
  }

  async search() {
    this.feedbackService.beginLoading();

    if (this.searchForm.invalid) {
      this.feedbackService.alert(
        'The form is invalid. Please correct all errors before submitting.',
      );
      this.feedbackService.provideFeedback();
    } else {
      try {
        const dateFrom =
          this.searchForm.controls.dateFrom.value !== ''
            ? new Date(this.searchForm.controls.dateFrom.value)
            : null;
        const dateThru =
          this.searchForm.controls.dateThru.value !== ''
            ? new Date(this.searchForm.controls.dateThru.value)
            : null;
        const response = await this.reportService
          .get(
            dateFrom,
            dateThru,
            this.searchForm.controls.cchStart.value,
            this.searchForm.controls.cchEnd.value,
            this.searchForm.controls.fisLegacyDeptNumber.value,
          )
          .toPromise();
        if (response) {
          await this.generateReport(response);
        }
      } catch (error) {
        console.log(error);
        this.feedbackService.alert(
          'Error generating report. Please try again.',
        );
      } finally {
        this.feedbackService.provideFeedback();
      }
    }
  }

  async generateReport(results: CchRollupReportResponse) {
    try {
      // Start the loading spinner
      this.feedbackService.beginLoading();

      // Prepare the report data
      const data = [
        {
          title: 'Records with CC ID Only & Records with CC ID & FIS ID',
          columns: [
            { header: 'Cost Center Rollup Number', key: 'cch', width: 25 },
            { header: 'Cost Center Rollup Name', key: 'cchName', width: 40 },
            {
              header: 'FIS Legacy System Dept. Number',
              key: 'fisDeptNumber',
              width: 30,
            },
            {
              header: 'FIS Legacy System Dept. Name',
              key: 'fisDeptName',
              width: 40,
            },
            {
              header: 'Total Subs Submitted',
              key: 'submittedTotal',
              width: 25,
            },
            { header: 'New Subs Executed', key: 'executedNew', width: 20 },
            { header: 'Modifications Executed', key: 'executedMod', width: 25 },
            { header: 'Total Subs Executed', key: 'executedTotal', width: 25 },
          ],
          rows:
            results.submittedAndExecutedByCchRollup.length > 0
              ? results.submittedAndExecutedByCchRollup.map((item) => [
                  item.cchRollup,
                  item.cchRollupName,
                  item.fisLegacyDeptNumber || '',
                  item.fisLegacyDeptName || '',
                  item.totalSubmitted,
                  item.newExecuted,
                  item.modsExecuted,
                  item.totalExecuted,
                ])
              : [], // user-friendly message - no results
          totals: [
            {
              label: 'Grand Total Submitted',
              value: results.grandTotalSubmitted,
              column: 5, // Column 'E'
            },
            {
              label: 'Grand Total Executed',
              value: results.grandTotalExecuted,
              column: 8, // Column 'H'
            },
          ],
        },
        {
          title: 'Records with FIS ID Only',
          columns: [
            { header: '', key: '', width: 25 }, // Empty column A
            { header: '', key: '', width: 40 }, // Empty column B
            {
              header: 'FIS Legacy Dept. Number',
              key: 'fisDeptNumber',
              width: 30,
            },
            { header: 'FIS Legacy Dept. Name', key: 'fisDeptName', width: 40 },
            {
              header: 'Total Subs Submitted',
              key: 'submittedTotal',
              width: 25,
            },
            { header: 'New Subs Executed', key: 'executedNew', width: 20 },
            { header: 'Modifications Executed', key: 'executedMod', width: 25 },
            { header: 'Total Subs Executed', key: 'executedTotal', width: 25 },
          ],
          rows:
            results.submittedAndExecutedByFIS.length > 0
              ? results.submittedAndExecutedByFIS.map((item) => [
                  '',
                  '',
                  item.fisLegacyDeptNumber,
                  item.fisLegacyDeptName,
                  item.totalSubmitted,
                  item.newExecuted,
                  item.modsExecuted,
                  item.totalExecuted,
                ])
              : [], // user-friendly message - no results
          totals: [
            {
              label: 'Grand Total Submitted',
              value: results.fisGrandTotalSubmitted,
              column: 5, // Column 'E'
            },
            {
              label: 'Grand Total Executed',
              value: results.fisGrandTotalExecuted,
              column: 8, // Column 'H'
            },
          ],
        },
      ];

      // generate excel call
      await this.excelExportMultipleDataSetsService.generateExcel(
        'SUBS CCH Rollup Report',
        'SUBS CCH Rollup Report',
        data,
        {
          value: `Date Range: ${this.searchForm.controls.dateFrom.value} - ${this.searchForm.controls.dateThru.value}
        CCH Start: ${this.searchForm.controls.cchStart.value}
        CCH End: ${this.searchForm.controls.cchEnd.value}
        FIS Legacy Dept Number: ${this.searchForm.controls.fisLegacyDeptNumber.value}`,
        },
      );
      this.feedbackService.completeLoading();
    } catch (error) {
      this.feedbackService.alert(error);
    }
  }

  clear() {
    this.searchForm.reset();
    this.feedbackService.clearAlert();
  }
  hasOneFieldFilled: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const dateFrom = control.get('dateFrom')?.value;
    const dateThru = control.get('dateThru')?.value;
    const cchStart = control.get('cchStart')?.value;
    const cchEnd = control.get('cchEnd')?.value;
    const fisLegacyDeptNumber = control.get('fisLegacyDeptNumber')?.value;

    if (dateFrom || dateThru || cchStart || cchEnd || fisLegacyDeptNumber) {
      return null; // Valid condition
    } else {
      return { atLeastOneRequired: true }; // Invalid condition
    }
  };

  hasbothDateFields: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const dateFrom = control.get('dateFrom')?.value;
    const dateThru = control.get('dateThru')?.value;

    if (dateFrom || dateThru) {
      if (dateFrom && dateThru) {
        return null;
      } else {
        return { bothDatesRequired: true };
      }
    }
  };

  hasbothCCHRoleupFields: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const cchStart = control.get('cchStart')?.value;
    const cchEnd = control.get('cchEnd')?.value;

    if (cchStart || cchEnd) {
      if (cchStart && cchEnd) {
        return null;
      } else {
        return { bothDatesRequired: true };
      }
    }
  };
}
