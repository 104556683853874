<div class="card" [formGroup]="form">
  <div class="card-header">
    {{ title }}
  </div>

  <div class="card-block">
    <div class="clr-row">
      <div class="clr-col-11">
        <subs-input
          label="* First name"
          controlName="firstName"
          maxLength="30"
        ></subs-input>
      </div>
      <div class="clr-col-1" style="text-align: center;">
        <button
          data-test-id="search-wu-pi"
          type="button"
          class="btn btn-icon"
          aria-label="warning"
          title="Employee LookUp"
          (click)="lookupEmployee()"
        >
          <clr-icon shape="search"></clr-icon>
        </button>
      </div>
    </div>

    <div class="clr-row">
      <subs-input
        class="clr-col-11"
        label="* Last name"
        controlName="lastName"
        maxLength="30"
      ></subs-input>
    </div>

    <div class="clr-row" *ngIf="!isPrincipalInvestigator">
      <subs-input
        class="clr-col-7"
        label="* Phone"
        controlName="phone"
        maxLength="30"
      ></subs-input>

      <subs-input
        class="clr-col-5"
        controlName="fax"
        labelStyle="width: 4rem;"
        maxLength="30"
      ></subs-input>
    </div>

    <subs-input
      label="* Email address"
      controlName="emailAddress"
      maxLength="70"
    ></subs-input>

    <div *ngIf="isPrincipalInvestigator">
      <div class="clr-row">
        <subs-input
          class="clr-col-7"
          label="Campus CD"
          controlName="campusCD"
          maxLength="1"
        ></subs-input>
        <subs-input
          class="clr-col-5"
          label="Campus Box"
          controlName="campusBox"
          labelStyle="width: 4rem;"
          maxLength="32"
        >
        </subs-input>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-4">
          <subs-input
            label="PI Department"
            controlName="principalInvestigatorDepartment"
            maxLength="20"
          >
          </subs-input>
        </div>

        <div class="clr-col-7">
          {{ DeptName }}
        </div>
      </div>
    </div>
  </div>
</div>

<subs-employee-lookup data-test-id="employee-lookup"> </subs-employee-lookup>

