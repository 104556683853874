<subs-form-layout [formGroup]="form" [hideFooter]="this.viewOnlyUser">
  <subs-form-content>
    <subs-alert [alertBehavior]="formLevelAlerts$"> </subs-alert>
    <subs-fixed-header [header]="headerFull"></subs-fixed-header>
    <div class="card">
      <div class="card-block card-container" [formGroup]="form">
        <div class="clr-row">
          <div class="clr-col-md">
            <button
              class="btn btn-sm btn-outline"
              (click)="addAdditionalYear()"
              data-test-id="btn-additional-year"
              *ngIf="canAddYear && !this.viewOnlyUser"
            >
              Add Additional Year
            </button>
          </div>
        </div>
        <div class="clr-row">
          <subs-input
            class="clr-col-md-4"
            controlName="proposalId"
            label="Proposal ID"
            labelLength="6"
            maxLength="25"
            helperText="{{ helperText }}"
          ></subs-input>

          <subs-input
            class="clr-col-md-4"
            controlName="awardDepartment"
            label="Award Department #"
            labelLength="6"
            maxLength="10"
          ></subs-input>

          <div class="clr-col-md-3">
            <clr-select-container>
              <label class="clr-col-12 clr-col-md-auto" style="width: 6rem;">
                Award Year
                <em
                  *ngIf="awardYear && awardYears"
                  style="font-size: 10px;font-weight: lighter;"
                  >{{ awardYear }} of {{ awardYears.length }}</em
                >
              </label>
              <select
                clrSelect
                class="clr-col-12 clr-col-md"
                data-test-id="awardYear"
                #awardYearSelect
                (change)="navigateToProject(+awardYearSelect.value)"
              >
                <option
                  *ngFor="let ay of awardYears"
                  [value]="ay.projectId"
                  [attr.selected]="
                    ay.awardYear == form.controls.awardYear.value ? true : null
                  "
                >
                  {{ ay.awardYear }}
                </option>
              </select>
            </clr-select-container>
          </div>
        </div>

        <div class="clr-row">
          <subs-input
            class="clr-col-md-4"
            controlName="awardNumber"
            label="Agency Award Number"
            labelLength="6"
            maxLength="128"
          ></subs-input>

          <subs-input
            class="clr-col-md-4"
            controlName="federalAwardId"
            label="FAIN"
            labelLength="6"
            maxLength="20"
          ></subs-input>

          <subs-yes-no
            class="clr-col-md"
            controlName="researchAndDevelopment"
            label="Is This Project R&D"
            labelLength="6"
          >
            <option value=""></option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </subs-yes-no>
        </div>

        <div class="clr-row">
          <subs-select
            class="clr-col-md-4"
            controlName="sponsorType"
            label="Type of sponsor"
            labelLength="6"
          >
            <option value=""></option>
            <option value="FG">Federal Government</option>
            <option value="FD">Foundation</option>
            <option value="IN">Industry</option>
            <option value="HE">Institution of Higher Education</option>
            <option value="IT">International</option>
            <option value="LG">Local Government</option>
            <option value="OT">Other</option>
            <option value="SG">State Government</option>
            <option value="VH">Voluntary Health</option>
          </subs-select>

          <subs-input
            class="clr-col-lg-4"
            controlName="originatingSponsor"
            labelLength="6"
            maxLength="200"
          ></subs-input>

          <subs-yes-no
            class="clr-col-md"
            controlName="stimulusAwarded"
            label="Stimulus Awarded"
            labelLength="6"
          >
            <option value=""></option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </subs-yes-no>
        </div>

        <div class="clr-row">
          <subs-input
            class="clr-col-md"
            controlName="sponsorName"
            labelLength="6"
            maxLength="200"
          ></subs-input>
        </div>

        <div class="clr-row">
          <subs-input
            class="clr-col"
            controlName="projectTitle"
            labelLength="6"
            maxLength="256"
          ></subs-input>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Current Budget Period
      </div>
      <div class="card-block" [formGroup]="form">
        <div class="clr-row">
          <subs-date
            class="clr-col-lg"
            controlName="grantFromDate"
            labelLength="6"
          >
          </subs-date>

          <subs-date
            class="clr-col-lg"
            controlName="grantThruDate"
            labelLength="6"
          >
          </subs-date>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        WashU Principal Investigator
      </div>

      <div class="card-block" [formGroup]="form">
        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="investigatorEmployeeId"
            label="Employee ID"
            labelLength="6"
          ></subs-static-text>

          <button
            data-test-id="search-wu-pi"
            type="button"
            class="btn btn-icon"
            aria-label="warning"
            title="Employee LookUp"
            (click)="lookupPrincipalInvestigator()"
          >
            <clr-icon shape="search"></clr-icon>
          </button>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="investigatorFirstName"
            label="First name"
            labelLength="6"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-md"
            controlName="investigatorLastName"
            label="Last name"
            labelLength="6"
          ></subs-static-text>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-md-9"
            controlName="investigatorEmail"
            label="Email"
            labelLength="6"
          ></subs-static-text>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="investigatorCampusCode"
            label="Campus code"
            labelLength="6"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-md"
            controlName="investigatorDept"
            label="Department"
            labelLength="6"
          ></subs-static-text>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="investigatorCampusBox"
            label="Campus box"
            labelLength="6"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-md"
            controlName="investigatorDeptName"
            label="Department name"
            labelLength="6"
          ></subs-static-text>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        WashU Department Administrator
      </div>
      <div class="card-block" [formGroup]="form">
        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="adminEmployeeId"
            label="Employee ID"
            labelLength="6"
          ></subs-static-text>
          <button
            data-test-id="search-wu-da"
            type="button"
            class="btn btn-icon"
            aria-label="warning"
            title="Employee LookUp"
            (click)="lookupDepartmentAdmin()"
          >
            <clr-icon shape="search"></clr-icon>
          </button>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="adminFirstName"
            label="First name"
            labelLength="6"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-md"
            controlName="adminLastName"
            label="Last name"
            labelLength="6"
          ></subs-static-text>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-md-9"
            controlName="adminEmail"
            label="Email"
            labelLength="6"
          ></subs-static-text>
        </div>

        <div class="clr-row">
          <subs-static-text
            class="clr-col-md"
            controlName="adminPhone"
            label="Campus phone"
            labelLength="6"
          ></subs-static-text>

          <subs-static-text
            class="clr-col-md"
            controlName="adminFax"
            label="Fax"
            labelLength="6"
          ></subs-static-text>
        </div>
      </div>
    </div>

    <subs-notice-of-award></subs-notice-of-award>

    <div *ngIf="form.controls.subrecipients.value" class="card">
      <div class="card-header">
        List of Subawards
      </div>
      <div class="card-block">
        <button
          *ngIf="form.controls.subrecipients.value.length > 0"
          class="btn btn-sm btn-outline"
          style="margin-bottom:12px;"
          data-test-id="export-sub-list"
          (click)="exportSubawardsList()"
        >
          Export
        </button>
        <subs-subawards-grid
          [subawardSummaryResults]="form.controls.subrecipients.value"
          [statuses]="activeStatusList"
          data-test-id="subawards-summary-grid"
        >
        </subs-subawards-grid>
      </div>
    </div>
  </subs-form-content>

  <subs-form-buttons *ngIf="!this.viewOnlyUser">
    <button
      *ngIf="!this.viewOnlyUser"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="save()"
      data-test-id="save"
    >
      Save
    </button>
    <button
      class="btn btn-outline"
      (click)="redirectToSearchPage()"
    >
      Return to Search
    </button>

    <button
      *ngIf="canAddCollaborator()"
      class="btn btn-outline"
      (click)="addCollaborator()"
      data-test-id="add-collaborator"
    >
      Add Collaborator
    </button>

    <button
      *ngIf="canDeleteProjectYear()"
      class="btn btn-outline"
      (click)="deleteProjectYear()"
      data-test-id="delete-project-year"
    >
      Delete Project Year
    </button>
  </subs-form-buttons>
</subs-form-layout>

<clr-modal [(clrModalOpen)]="showAddYearModal" [clrModalClosable]="false">
  <h3 class="modal-title">
    Adding Additional Year
  </h3>
  <div class="modal-body">
    Adding an Additional Year will clone your current year records and allow you
    to enter information for the new year. Please Assign the new RMS#, if it has
    changed.
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmAdditionalYear()"
      data-test-id="confirm-additionalYear"
    >
      OK
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardAdditionalYear()"
      data-test-id="cancel-additionalYear"
    >
      Cancel
    </button>
  </div>
</clr-modal>

<clr-modal
  [(clrModalOpen)]="showDeleteModal"
  data-test-id="project-delete-modal"
>
  <h3 class="modal-title">
    Confirm Delete?
  </h3>

  <div class="modal-body">
    <p>
      Are you sure you wish to delete this project award year and all associated
      Sub Awards?
    </p>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="showDeleteModal = false"
    >
      Cancel
    </button>

    <button
      type="button"
      class="btn btn-primary"
      (click)="delete()"
      data-test-id="confirm-delete-project-year"
    >
      Delete
    </button>
  </div>
</clr-modal>

<subs-employee-lookup data-test-id="employee-lookup"> </subs-employee-lookup>

<clr-modal
  [(clrModalOpen)]="showUnsavedChangesModal"
  [clrModalClosable]="false"
>
  <h3 class="modal-title">
    You have unsaved changes.
  </h3>
  <div class="modal-body">
    <subs-alert [alertBehavior]="alert$"></subs-alert>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [clrLoading]="submitState$ | async"
      (click)="confirmSave()"
      data-test-id="confirm-save-before-navigate"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="discardChanges()"
    >
      Discard
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [clrLoading]="submitState$ | async"
      (click)="cancelNavigation()"
    >
      Cancel
    </button>
  </div>
</clr-modal>
