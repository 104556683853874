<div class="clr-row" style="height: 100%">
  <div class="clr-col-12" style="height: 100%">
    <div class="card  training-card">
      <h3 id="training-header" class="clr-col-6">SUBSystem Access and Resources</h3>
      <div class="card-block" style="height: 90%">
        <span>
          <Label
            >For access, email
            <a href="mailto: wucontracts@email.wustl.edu">WU CONTRACTS</a> with
            your supervisor’s approval.</Label
          >
        </span>
        <!-- Innner Card -->
        <div
          id="home-training-item"
          class="card"
          *ngFor="let training of trainingViews$ | async; let i = index"
          style="width: 100%;"
        >
          <h5 class="card-header">{{ training.label }}</h5>
          <div class="card-block">
            <button
              (click)="download(training.trainingFileId, training.fileName)"
              class="btn btn-sm btn-link"
            >
              download
            </button>
          </div>
        </div>
        <!-- End of inner Card -->
      </div>
    </div>
  </div>
</div>
