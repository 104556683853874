import { format, parseISO, parse, formatISO } from 'date-fns';

export class DateConvert {
  static convertDateToLocalCulture(date: string | null): string | null {
    if (!date) {
      return null;
    }
    return format(parseISO(date), 'P');
  }

  static convertDateTimeToLocalCulture(date: string | null): string | null {
    if (!date) {
      return null;
    }
    return format(parseISO(date), 'P p');
  }

  static convertDateToInvariantCulture(date: string | null): string | null {
    if (!date) {
      return null;
    }

    const parsedDate = parse(date, 'P', new Date());

    return formatISO(parsedDate, {
      representation: 'date',
    });
  }
}
